// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bpresentv-2-js": () => import("../src/pages/bpresentv2.js" /* webpackChunkName: "component---src-pages-bpresentv-2-js" */),
  "component---src-pages-castie-js": () => import("../src/pages/castie.js" /* webpackChunkName: "component---src-pages-castie-js" */),
  "component---src-pages-footer-js": () => import("../src/pages/footer.js" /* webpackChunkName: "component---src-pages-footer-js" */),
  "component---src-pages-googleintern-js": () => import("../src/pages/googleintern.js" /* webpackChunkName: "component---src-pages-googleintern-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infovis-js": () => import("../src/pages/infovis.js" /* webpackChunkName: "component---src-pages-infovis-js" */),
  "component---src-pages-ntl-js": () => import("../src/pages/ntl.js" /* webpackChunkName: "component---src-pages-ntl-js" */),
  "component---src-pages-pawesome-js": () => import("../src/pages/pawesome.js" /* webpackChunkName: "component---src-pages-pawesome-js" */),
  "component---src-pages-portfolio-js": () => import("../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-resume-js": () => import("../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-rr-js": () => import("../src/pages/rr.js" /* webpackChunkName: "component---src-pages-rr-js" */)
}

